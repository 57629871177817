import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      <h1 className='w-full text-3xl font-bold text-[#FFFFFF]'>PEEL CHESS</h1>
      <ul className='hidden md:flex'>
        <li className='p-4'>
          <Link to="/">Home</Link>
        </li>
        {/* <li className='p-4'><Link to="/company">Company</Link></li> */}
        <li className='p-4'>
          <Link to="/about">About</Link>
        </li>
        <li className='p-4'>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div onClick={handleNav} className='block md:hidden cursor-pointer'>
        {!nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <div
        className={
          !nav
            ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#372C2E] ease-in-out duration-500'
            : 'fixed left-[-100%]'
        }
      >
        <h1 className='w-full text-3xl font-bold text-[#FFFFFF] pl-3'>PEEL CHESS</h1>

        <ul className='uppercase'>
          <li className='p-4 border-b border-gray-600'>
            <Link to="/home">Home</Link>
          </li>
          <li className='p-4 border-b border-gray-600'>
            <Link to="/about">About</Link>
          </li>
          <li className='p-4 border-b border-gray-600'>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
