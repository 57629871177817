import React from 'react'
import Typed from 'react-typed'

const Hero = () => {
  return (
    <div className="text-white">
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>

          <p className='text-[#DE9E48] font-bold p-2'>GROWING WITH DATA ANALYTICS</p>

          <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>Grow with data.</h1>

          <div className='flex justify-center items-center'>

            <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>First, flexible finacing for</p>
            
            <Typed className='md:text-5xl sm:text-4xl text-xl font-bold pl-4' strings={['BTB', 'BTC','SAAS']} typespeed={120} backSpeed={140} loop/>          
          </div>
          <p className='md:text-2xl text-xl font-bold text-[#ae6b3c]'>test</p>
        <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3'>Get Started</button>
      </div>
    </div>

  )
}

export default Hero