import React from 'react'
import Hero from '../components/Hero';
import Analytics from '../components/Analytics';


function Home() {
  return (
    <div>
      <Hero />
      <Analytics />
    </div>
  );
}

export default Home;
